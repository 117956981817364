import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { green, red } from '@material-ui/core/colors';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import clsx from 'clsx';
import _ from 'lodash';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IOrganizationDto, IProjectDto } from '../../../types/dto.type';
import { fetchExtendClaims } from '../../api/authZ.api';
import { deleteResource, get, post, put } from '../../api/axios';
import { apiUrls, listChannelsUrl, organizationUrl } from '../../api/urls';
import { getOrganizationProjectsUrl, projectUrl } from '../../common/adminUrls';
import { AuthZExtendClaimsReq } from '../../common/authZ.type';
import history from '../../History';
import { CONFIG } from '../../services';
import { IChannelPermission, IProjectInfo } from '../../types/admin.type';
import { OrganizationParams } from '../../types/props.type';
import ConfirmationDialog from './ConfirmationDialog';
import GenericDialog from './GenericDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '0em'
    },
    title: {
      fontSize: 14,
      marginBottom: 0
    },
    childCard: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1)
    },
    grid: {
      paddingLeft: '1em'
    },
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1)
    },
    spacer: {
      flexGrow: 1
    },
    table: {
      marginTop: theme.spacing(2)
    },
    formElementTopPadding: {
      marginTop: '2em'
    },
    wrapper: {
      height: theme.spacing(8),
      margin: theme.spacing(1),
      position: 'relative'
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    offShape: {
      backgroundColor: red[500],
      width: 15,
      height: 15
    },
    onShape: {
      backgroundColor: green[500],
      width: 15,
      height: 15
    },
    shapeCircle: {
      borderRadius: '50%'
    }
  })
);

const getAssignedChannels = async (
  iss: string,
  sub: string,
  defaultPermissions: Map<string, boolean>
): Promise<IChannelPermission[]> => {
  const req: AuthZExtendClaimsReq = {
    prefixes: CONFIG.authZApi.prefixes,
    claims: {
      email: '',
      iss, // iss is organizationId since orgName not easily available - determine in backend
      sub
    }
  };

  const claims = await fetchExtendClaims(req);
  // Create a new map copy here so defaultPermissions will not be modified
  // should be the same as _.cloneDeep
  const map = new Map(defaultPermissions);
  if (map.size === 0) {
    return [];
  }
  // check claim is for a channel and if so set it
  claims[req.claims.email].forEach(val => {
    const tokens: string[] = val.split('/');
    const role = tokens[tokens.length - 2];
    map.set(role, true);
  });

  const channels = toArray(map);
  return channels;
};

const toArray = (map: Map<string, boolean>) => {
  return Array.from(map, ([id, allowed]) => ({ id, allowed }));
};

const populateDefaultChannelPermissions = (
  channelCount: number
): Map<string, boolean> => {
  const result: Map<string, boolean> = new Map<string, boolean>();
  for (let id = 1; id <= channelCount; id++) {
    result.set(id.toString(), false);
  }
  return result;
};

export default function Projects(
  props: RouteComponentProps<OrganizationParams>
): JSX.Element {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const organizationId = props.match.params.organizationId;

  const [projects, setProjects] = useState<IProjectInfo[]>([]);
  const [projectToEdit, setProjectToEdit] = useState<IProjectInfo>();
  const [openEditProjectDialog, setOpenEditProjectDialog] = useState(false);
  const [openAddProjectDialog, setOpenAddProjectDialog] = useState(false);
  const [textFieldError, setTextFieldError] = useState('');

  // Edit Project Dialog Properties
  const [projectName, setProjectName] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [
    isLoadingChannelPermissions,
    setIsLoadingChannelPermissions
  ] = useState<boolean>(true);

  const [defaultChannelPermissions, setDefaultChannelPermissions] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  // eslint-disable-next-line
  const [channelPermissions, setChannelPermissions] = useState<
    IChannelPermission[]
  >([]);
  const [dialogBusy, setDialogBusy] = useState(false);
  const [previousChannelPermissions, setPreviousChannelPermissions] = useState<
    IChannelPermission[]
  >([]);
  const [organizationName, setOrganizationName] = useState<string>('');

  useEffect(() => {
    const fetchOrganization = async () => {
      const url = organizationUrl(organizationId);
      const organizationDto = (await get(url)) as IOrganizationDto;
      setOrganizationName(organizationDto.name);
    };
    fetchOrganization();
  }, [organizationId]);

  useEffect(() => {
    const data: IProjectInfo[] = [];
    const fetchData = async () => {
      const channels = await get(listChannelsUrl());
      const permissions = populateDefaultChannelPermissions(channels.length);
      setDefaultChannelPermissions(permissions);
      const clone = _.cloneDeep(permissions);
      setChannelPermissions(toArray(clone));

      const url = getOrganizationProjectsUrl(organizationId);
      const dtos = (await get(url)) as IProjectDto[];

      for (const dto of dtos) {
        const assigned = await getAssignedChannels(
          organizationId,
          dto.id,
          permissions
        );
        data.push({
          id: dto.id,
          name: dto.name,
          startDate: new Date(dto.startDate),
          endDate: new Date(dto.plannedEndDate),
          numberOfUsers: !dto.numberOfUsers ? 0 : dto.numberOfUsers,
          channelPermissions: assigned,
          enabled: dto.enabled
        });
      }

      setProjects(data);
      setIsLoading(false);
    };
    fetchData();
  }, [organizationId]);

  const resetChannelPermissions = () => {
    setChannelPermissions(toArray(defaultChannelPermissions));
    setPreviousChannelPermissions(toArray(defaultChannelPermissions));
  };

  const handleOpenAddProjectDialog = () => {
    setOpenAddProjectDialog(true);
  };

  const handleCloseAddProjectDialog = () => {
    setOpenAddProjectDialog(false);
    resetChannelPermissions();
    setIsLoadingChannelPermissions(true);
    setTextFieldError('');
    setDialogBusy(false);
  };

  const handleAddProject = async () => {
    try {
      setDialogBusy(true);
      const url = getOrganizationProjectsUrl(organizationId);
      const dto = (await post(url, {
        projectName,
        organizationId,
        startDate,
        plannedEndDate: endDate,
        enabled: true
      })) as IProjectDto;

      const urlAuthZ = apiUrls.authZAssignChannels();
      const resAuthZ = await put(urlAuthZ, {
        iss: organizationId,
        sub: dto.id,
        channelsToAdd: getChannelsToAdd(),
        channelsToRemove: []
      });

      if (resAuthZ && resAuthZ.status === 200) {
        setProjects([
          ...projects,
          {
            id: dto.id,
            name: dto.name,
            startDate: new Date(dto.startDate),
            endDate: new Date(dto.plannedEndDate),
            numberOfUsers: 0,
            channelPermissions,
            enabled: dto.enabled
          }
        ]);
        setPreviousChannelPermissions(channelPermissions);
      }

      setDialogBusy(false);
      handleCloseAddProjectDialog();
      snackbar.enqueueSnackbar(`Project ${projectName} successfully created`, {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const getChannelsToAdd = (): string[] => {
    const previousChannelList = getSelectedChannels(previousChannelPermissions);
    const newChannelList = getSelectedChannels(channelPermissions);

    const diff = newChannelList.filter(x => !previousChannelList.includes(x));
    return diff;
  };

  const getChannelsToRemove = (): string[] => {
    const previousChannelList = getSelectedChannels(previousChannelPermissions);
    const newChannelList = getSelectedChannels(channelPermissions);

    const diff = previousChannelList.filter(x => !newChannelList.includes(x));
    return diff;
  };

  const handleEditProject = async () => {
    try {
      setDialogBusy(true);
      if (projectToEdit) {
        const id = projectToEdit.id;
        const url = projectUrl(organizationId, projectToEdit.id);
        const response = await put(url, {
          name: projectName,
          startDate,
          plannedEndDate: endDate,
          enabled: projectToEdit.enabled
        });

        if (response && response.status === 200) {
          const urlAuthZ = apiUrls.authZAssignChannels();
          const resAuthZ = await put(urlAuthZ, {
            iss: organizationId,
            sub: projectToEdit.id,
            channelsToAdd: getChannelsToAdd(),
            channelsToRemove: getChannelsToRemove()
          });

          if (resAuthZ && resAuthZ.status === 200) {
            projectToEdit.name = projectName;
            projectToEdit.startDate = startDate;
            projectToEdit.endDate = endDate;

            projectToEdit.channelPermissions = _.cloneDeep(channelPermissions);
            // Replace project matching id
            const index = _.findIndex(projects, { id });
            projects.splice(index, 1, projectToEdit);
            setPreviousChannelPermissions(channelPermissions);
          }
        }

        setDialogBusy(false);
        handleCloseEditProjectDialog();
        snackbar.enqueueSnackbar(
          `Project ${projectName} successfully updated`,
          {
            variant: 'success'
          }
        );
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const toggleProjectEnabled = async (
    project: IProjectInfo,
    enabled: boolean
  ) => {
    const url = projectUrl(organizationId, project.id);
    const response = await put(url, {
      name: project.name,
      startDate: project.startDate,
      plannedEndDate: project.endDate,
      enabled
    });

    if (response && response.status === 200) {
      project.enabled = enabled;
      const result = _.unionBy([project], projects, 'id');
      setProjects(result);
    }
  };

  const enableProjectOnClick = async (row: IProjectInfo | IProjectInfo[]) => {
    if (row) {
      const projectInfo = row as IProjectInfo;
      await toggleProjectEnabled(projectInfo, true);
    }
  };

  const disableProjectOnClick = async (row: IProjectInfo | IProjectInfo[]) => {
    if (row) {
      const projectInfo = row as IProjectInfo;
      await toggleProjectEnabled(projectInfo, false);
    }
  };

  const editProjectOnClick = async (row: IProjectInfo | IProjectInfo[]) => {
    if (row) {
      const projectInfo = row as IProjectInfo;
      if (projectInfo) {
        setProjectToEdit(projectInfo);
        setOpenEditProjectDialog(true);
        setProjectName(projectInfo.name);
        setStartDate(projectInfo.startDate);
        setEndDate(projectInfo.endDate);
      }

      const channels = await getAssignedChannels(
        organizationId,
        projectInfo.id,
        defaultChannelPermissions
      );
      setIsLoadingChannelPermissions(false);
      setChannelPermissions(channels);
      // We need to create a deep clone as [...channels] does NOT
      // make a copy of the containing objects
      const previous = _.cloneDeep(channels);
      setPreviousChannelPermissions(previous);
    }
  };

  const handleCloseEditProjectDialog = () => {
    setOpenEditProjectDialog(false);
    resetChannelPermissions();
    setIsLoadingChannelPermissions(true);
    setTextFieldError('');
    setDialogBusy(false);
  };

  const [projectToDelete, setProjectToDelete] = useState<IProjectInfo>();
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState(false);

  const handleCloseDeleteProjectDialog = () => {
    setOpenDeleteProjectDialog(false);
    setDialogBusy(false);
  };

  const handleDeleteProject = async () => {
    try {
      setDialogBusy(true);
      if (projectToDelete) {
        const id = projectToDelete.id;
        const url = projectUrl(organizationId, projectToDelete.id);
        const response = await deleteResource(url);

        if (response && response.status === 200) {
          _.remove(projects, { id });
          setProjects(projects.filter(x => x.id !== id));

          // remove any channel related scopes for this project
          const urlAuthZ = apiUrls.authZAssignChannels();
          const resAuthZ = await put(urlAuthZ, {
            iss: organizationId,
            sub: projectToDelete.id,
            channelsToAdd: [],
            channelsToRemove: getSelectedChannels(
              projectToDelete.channelPermissions
            )
          });

          if (resAuthZ && resAuthZ.status === 200) {
            setDialogBusy(false);
            handleCloseDeleteProjectDialog();
            snackbar.enqueueSnackbar(
              `Project ${projectName} successfully deleted`,
              {
                variant: 'success'
              }
            );
          }
        }
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error'
      });
    }
  };

  const deleteProjectOnClick = (row: IProjectInfo | IProjectInfo[]): void => {
    if (row) {
      const projectInfo = row as IProjectInfo;
      if (projectInfo) {
        setProjectToDelete(projectInfo);
        setOpenDeleteProjectDialog(true);
      }
    }
  };

  const handleProjectNameChange = (e: any) => {
    // Check if project with the same name already exists
    setProjectName(e.target.value);
  };

  const handleStartDateChange = (date: Date | null) => {
    // Valid if start date is sensible based on some contraints?
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    // Valid if end date is sensible based on some contraints?
    if (date) {
      setEndDate(date);
    }
  };

  const getSelectedChannels = (channels: IChannelPermission[]): string[] => {
    const currentChannelList: string[] = [];
    for (const channel of channels) {
      if (channel.allowed) {
        currentChannelList.push(channel.id);
      }
    }
    return currentChannelList;
  };

  const handleChannelPermissionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.name;
    const checked = event.target.checked;

    const newChannels = _.cloneDeep(channelPermissions);
    const index = _.findIndex(newChannels, e => e.id === name);
    newChannels[index].allowed = checked;
    setChannelPermissions(newChannels);
  };

  const channelPermissionsTableRender = (rowData: IProjectInfo) => {
    const streams: string[] = [];
    for (const permission of rowData.channelPermissions) {
      if (permission.allowed) {
        streams.push(permission.id);
      }
    }
    if (streams.length > 0) {
      return streams.join(', ');
    }
    return 'No streams assigned';
  };

  const renderEnabled = (enabled: boolean): any => {
    if (enabled) {
      return <div className={clsx(classes.onShape, classes.shapeCircle)} />;
    } else {
      return <div className={clsx(classes.offShape, classes.shapeCircle)} />;
    }
  };

  const renderDate = (date: Date): string => {
    return date.toLocaleDateString('en-AU');
  };

  const selectProject = (
    _event: React.MouseEvent | undefined,
    row: IProjectInfo | undefined
  ) => {
    if (row) {
      history.push(`/organizations/${organizationId}/projects/${row.id}/users`);
    }
  };

  const channelIds = Array.from(defaultChannelPermissions.keys());

  const getChannelPermission = (id: string): boolean => {
    const channel = channelPermissions.filter(x => x.id === id)[0];
    if (channel) {
      return channel.allowed;
    }
    return false;
  };

  return (
    <>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color='primary'
          variant='contained'
          onClick={handleOpenAddProjectDialog}
        >
          Add Project
        </Button>
      </div>
      <div className={classes.table}>
        <MaterialTable
          isLoading={isLoading}
          title={`${organizationName} projects`}
          columns={[
            { title: 'Name', field: 'name' },
            {
              title: 'Enabled',
              field: 'enabled',
              render: row => renderEnabled(row.enabled)
            },
            {
              title: 'Start date',
              field: 'startDate',
              type: 'date',
              render: row => renderDate(row.startDate)
            },
            {
              title: 'Planned end date',
              field: 'endDate',
              type: 'date',
              render: row => renderDate(row.endDate)
            },
            {
              title: 'Number of users assigned',
              field: 'numberOfUsers',
              type: 'numeric'
            },
            {
              title: 'Assigned streams',
              field: 'channelPermissions',
              render: channelPermissionsTableRender
            }
          ]}
          data={_.orderBy(projects, ['name'], ['asc'])}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Edit Project',
              onClick: (_event, row) => editProjectOnClick(row)
            },
            rowData => {
              return rowData.enabled
                ? {
                    icon: 'videocam_off',
                    tooltip: 'Disable project',
                    onClick: (_event, row) => disableProjectOnClick(row)
                  }
                : {
                    icon: 'videocam',
                    tooltip: 'Enable project',
                    onClick: (_event, row) => enableProjectOnClick(row)
                  };
            },
            {
              icon: 'delete_forever',
              tooltip: 'Delete Project',
              onClick: (_event, row) => deleteProjectOnClick(row)
            }
          ]}
          options={{
            actionsColumnIndex: -1
          }}
          onRowClick={selectProject}
        />
      </div>

      <GenericDialog
        open={openAddProjectDialog}
        title={'Add Project'}
        message={'To add an project, please enter the name here.'}
        onClose={handleCloseAddProjectDialog}
        okButtonText={'Add'}
        okButtonDisabled={
          projectName.length === 0 || textFieldError.length !== 0
        }
        okHandler={handleAddProject}
        cancelHandler={handleCloseAddProjectDialog}
        loading={dialogBusy}
      >
        <TextField
          autoFocus={true}
          error={textFieldError.length !== 0}
          helperText={textFieldError}
          margin='dense'
          id='name'
          label='Project Name'
          onChange={handleProjectNameChange}
          type='text'
          fullWidth={true}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container={true} justify='space-around'>
            <Grid item={true} sm={12} md={6}>
              <KeyboardDatePicker
                disableToolbar={true}
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                id='start-date-picker-inline'
                label='Start Date'
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                autoOk={true}
              />
            </Grid>
            <Grid item={true} sm={12} md={6} className={classes.grid}>
              <KeyboardDatePicker
                disableToolbar={true}
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                id='end-date-picker-inline'
                label='End Date'
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                autoOk={true}
              />
            </Grid>
            <Grid item={true} sm={12} className={classes.formElementTopPadding}>
              <FormLabel>Assign Stream permissions</FormLabel>
              <FormGroup row={true}>
                {channelIds.map(id => (
                  <FormControlLabel
                    key={id}
                    control={
                      <Checkbox
                        onChange={handleChannelPermissionChange}
                        name={id}
                        color='primary'
                      />
                    }
                    label={`Stream ${id}`}
                  />
                ))}
              </FormGroup>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </GenericDialog>

      <GenericDialog
        open={openEditProjectDialog}
        title={'Edit Project'}
        message={'Edit project properties.'}
        onClose={handleCloseEditProjectDialog}
        okButtonText={'Ok'}
        okButtonDisabled={
          projectName.length === 0 || textFieldError.length !== 0
        }
        okHandler={handleEditProject}
        cancelHandler={handleCloseEditProjectDialog}
        aria-labelledby='form-dialog-title'
        maxWidth={'md'}
        loading={dialogBusy}
      >
        <TextField
          autoFocus={true}
          margin='normal'
          id='name'
          label='Project Name'
          onChange={handleProjectNameChange}
          defaultValue={projectName}
          fullWidth={true}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container={true} justify='space-around'>
            <Grid item={true} sm={12} md={6}>
              <KeyboardDatePicker
                disableToolbar={true}
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                id='start-date-picker-inline'
                label='Start Date'
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                autoOk={true}
              />
            </Grid>
            <Grid item={true} sm={12} md={6} className={classes.grid}>
              <KeyboardDatePicker
                disableToolbar={true}
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                id='end-date-picker-inline'
                label='End Date'
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                autoOk={true}
              />
            </Grid>

            <Grid item={true} sm={12} className={classes.formElementTopPadding}>
              <FormLabel>Assign stream permissions</FormLabel>
              {isLoadingChannelPermissions ? (
                <div className={classes.wrapper}>
                  <CircularProgress size={24} className={classes.progress} />
                </div>
              ) : (
                <FormGroup row={true}>
                  {channelIds.map(id => (
                    <FormControlLabel
                      key={id}
                      control={
                        <Checkbox
                          checked={getChannelPermission(id)}
                          onChange={handleChannelPermissionChange}
                          name={id}
                          color='primary'
                        />
                      }
                      label={`Stream ${id}`}
                    />
                  ))}
                </FormGroup>
              )}
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </GenericDialog>

      <ConfirmationDialog
        open={openDeleteProjectDialog}
        title={`Are you sure you want to delete ${
          projectToDelete === undefined ? 'this project' : projectToDelete.name
        }?`}
        onClose={handleCloseDeleteProjectDialog}
        message={`Deleting a project will remove access to all assigned users.`}
        yesHandler={handleDeleteProject}
        yesButtonDisabled={projectToDelete === undefined}
        noHandler={handleCloseDeleteProjectDialog}
        loading={dialogBusy}
      />
    </>
  );
}
