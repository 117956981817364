import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import useInterval from '@use-it/interval';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { get, put } from '../../api/axios';
import { listChannelsUrl } from '../../api/urls';
import { startChannelUrl, stopChannelUrl } from '../../common/adminUrls';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  table: {
    marginTop: theme.spacing(2)
  },
  running: {
    color: 'green',
    display: 'flex'
  },
  runningIcon: {
    marginRight: theme.spacing(1)
  },
  idle: {
    color: 'grey'
  },
  progress: {
    display: 'flex'
  },
  progressIcon: {
    marginRight: theme.spacing(1)
  }
}));

const INTERVAL = 5000;

interface IChannelInfo {
  id: string;
  name: string;
  state: string;
  channelClass: string;
  arn: string;
}

export default function Channels(): JSX.Element {
  const defaultChannelsState: IChannelInfo[] = [];
  const [channels, setChannels] = useState(defaultChannelsState);
  const [isLoading, setIsLoading] = useState(true);

  const snackbar = useSnackbar();
  const classes = useStyles();

  const fetchChannels = async () => {
    const data: IChannelInfo[] = [];
    const url = listChannelsUrl();
    const dtos = await get(url);
    const sortedByName = dtos
      .slice()
      .sort((a: any, b: any) =>
        a.Name.localeCompare(b.Name, undefined, { numeric: true })
      );
    for (const dto of sortedByName) {
      data.push({
        id: dto.Id,
        name: dto.Name,
        state: dto.State,
        channelClass: dto.ChannelClass,
        arn: dto.Arn
      });
    }
    setChannels(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchChannels();
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    fetchChannels();
  }, INTERVAL);

  const renderState = (channelInfo: IChannelInfo) => {
    if (channelInfo.state === 'RUNNING') {
      return (
        <div className={classes.running}>
          <CheckCircleOutline className={classes.runningIcon} />
          <Typography>{channelInfo.state}</Typography>
        </div>
      );
    } else if (channelInfo.state === 'IDLE') {
      return (
        <div className={classes.idle}>
          <Typography>{channelInfo.state}</Typography>
        </div>
      );
    } else if (
      channelInfo.state === 'STARTING' ||
      channelInfo.state === 'STOPPING'
    ) {
      return (
        <div className={classes.progress}>
          <CircularProgress size={20} className={classes.progressIcon} />
          <Typography>{channelInfo.state}</Typography>
        </div>
      );
    }
    return channelInfo.state;
  };

  const startChannel = async (row: IChannelInfo | IChannelInfo[]) => {
    if (row) {
      const channelInfo = row as IChannelInfo;
      const url = startChannelUrl(channelInfo.id);
      const response = await put(url, {});
      if (response?.status === 200) {
        snackbar.enqueueSnackbar(`Successfully starting ${channelInfo.name}`, {
          variant: 'success'
        });
      }
    }
  };

  const stopChannel = async (row: IChannelInfo | IChannelInfo[]) => {
    if (row) {
      const channelInfo = row as IChannelInfo;
      const url = stopChannelUrl(channelInfo.id);
      const response = await put(url, {});
      if (response?.status === 200) {
        snackbar.enqueueSnackbar(`Successfully stopping ${channelInfo.name}`, {
          variant: 'success'
        });
      }
    }
  };

  return (
    <div>
      <div className={classes.table}>
        <MaterialTable
          isLoading={isLoading}
          title='Channels'
          columns={[
            {
              title: 'Name',
              field: 'name',
              sorting: false
            },
            {
              title: 'State',
              field: 'state',
              sorting: false,
              render: row => renderState(row)
            },
            {
              title: 'ARN',
              field: 'arn',
              sorting: false
            },
            {
              title: 'Channel class',
              field: 'channelClass',
              sorting: false
            }
          ]}
          data={channels}
          actions={[
            rowData => {
              return rowData.state === 'RUNNING'
                ? {
                    icon: 'stop',
                    tooltip: 'Stop channel',
                    onClick: (_event, row) => stopChannel(row)
                  }
                : {
                    icon: 'play_arrow',
                    tooltip: 'Start channel',
                    onClick: (_event, row) => startChannel(row)
                  };
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10
          }}
        />
      </div>
    </div>
  );
}
