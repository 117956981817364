// tslint:disable-next-line: no-submodule-imports
import { useEffect, useState } from 'react';
import { get } from '../api/axios';
import { listChannelsUrl } from '../api/urls';

const DEFAULT_CHANNEL_COUNT = 8;

export default function useChannelCount(): number {
  const [channelCount, setChannelCount] = useState(DEFAULT_CHANNEL_COUNT);

  useEffect(() => {
    get(listChannelsUrl()).then(channels => {
      setChannelCount(channels.length);
    });
  }, [channelCount]);

  return channelCount;
}
