import {
  Card,
  CardActions,
  CardMedia,
  createStyles,
  Divider,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip
} from '@material-ui/core';
import { Edit, Hd, LastPage } from '@material-ui/icons';
// tslint:disable-next-line:no-submodule-imports
import Skeleton from '@material-ui/lab/Skeleton';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link as RouterLink } from 'react-router-dom';
import { get, post } from '../api/axios';
import { streamNameUrl } from '../api/urls';
import { adminStreamNamesUrl } from '../common/adminUrls';
import { getStreamRoute } from '../routes/Routes';
import { AUTH, AUTHZ } from '../services';
import GenericDialog from './admin/GenericDialog';
import { getStreamUrl } from './streamInfo';

const segmentLength = 1;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    childCard: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1)
    },
    playerWrapper: {
      position: 'relative',
      paddingTop: '56.25%'
    },
    reactPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    button: {
      padding: 0
    },
    progress: {
      position: 'relative',
      top: '50%',
      margin: '1em 1em'
    },
    spacer: {
      flexGrow: 1
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: theme.spacing(0.5)
    },
    formControl: {
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    channelText: {
      fontSize: '1.2em',
      paddingRight: theme.spacing(2)
    }
  })
);

interface StreamProps {
  streamId: number;
  pos: string;
  streamIds: number[];
  projectId: string;
  videoLayout: any;
  setVideoLayout: any;
  layoutName: string;
  streamNames: { [id: number]: string };
  propogateStreamNameChange: any;
}

export default function Stream(props: StreamProps): JSX.Element {
  const classes = useStyles();
  const projectStreamProps = props;
  const streamId = projectStreamProps.streamId;
  const initialPlayerRefs: Array<RefObject<ReactPlayer>> = [];
  const playerRefs = useRef(initialPlayerRefs);
  const [openRenameStreamDialog, setOpenChangeStreamNameDialog] = useState(
    false
  );
  const defaultStreamNames: { [id: number]: string } = {};
  const [streamNames, setStreamNames] = useState(defaultStreamNames);
  const [streamIdToChange, setStreamIdToChange] = useState(0);
  const [textFieldError, setTextFieldError] = useState('');
  const [streamName, setStreamName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  playerRefs.current[streamId] = React.createRef<ReactPlayer>();

  useEffect(() => {
    if (Object.entries(props.streamNames).length !== 0) {
      setStreamNames(props.streamNames);
      setLoading(false);
    }
  }, [props.streamNames]);

  useEffect(() => {
    const fetchIsAdmin = async () => {
      if (AUTH.isAuthenticated()) {
        setIsAdmin(await AUTHZ.permitAppRole('admin'));
      }
    };

    fetchIsAdmin();
  }, []);

  const handleOnError = (e: any, _data: any) => {
    console.log('Playback error', e);
  };

  const handleSeekLatest = (sid: number): void => {
    const playerRef = playerRefs.current[sid];
    if (playerRef.current) {
      const player = playerRef.current;
      const duration = player.getDuration();
      player.seekTo(duration - segmentLength);
    }
  };

  const handleEdit = (sid: number): void => {
    setStreamIdToChange(sid);
    setOpenChangeStreamNameDialog(true);
  };

  const handleCloseRenameStreamDialog = () => {
    setOpenChangeStreamNameDialog(false);
    setTextFieldError('');
  };

  const handleRenameStream = async () => {
    const renameStreamResponse = await post(adminStreamNamesUrl(), {
      id: streamIdToChange.toString(),
      name: streamName
    });
    if (renameStreamResponse) {
      handleCloseRenameStreamDialog();
      const streamNameResponse = await get(
        streamNameUrl(streamIdToChange.toString())
      );
      if (streamNameResponse) {
        // Use all the properties on state streamNames to create a new object,
        // then set the property represented by [streamIdToChange]
        // to a new value streamNameResponse.name.
        // same as state[streamIdToChange] = streamNameResponse.name;
        const state = {
          ...streamNames,
          [streamIdToChange]: streamNameResponse.name
        };
        props.propogateStreamNameChange(state);
      }
    }
  };

  const streamNameExists = (value: string): boolean => {
    for (const id in streamNames) {
      if (streamNames[id].toLowerCase() === value.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const handleStreamNameChange = (e: any) => {
    // Check if stream name already exists
    if (streamNameExists(e.target.value)) {
      setTextFieldError('Stream name already exist');
    } else {
      setTextFieldError('');
    }
    setStreamName(e.target.value);
  };

  // Gets the position where the specified stream is allocated o/w null
  const getStreamPosition = (sid: number, sessionLayout: any) => {
    let existingVideoPos: any = null;

    if (sessionLayout[props.projectId][props.layoutName]) {
      for (const [key, value] of Object.entries(
        sessionLayout[props.projectId][props.layoutName]
      )) {
        if (value === sid) {
          existingVideoPos = key;

          break;
        }
      }
    }

    return existingVideoPos;
  };

  const handleStreamSelection = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const videoPos = event.target.name || 'pos11';
    const selectedStreamId = event.target.value as number;
    const sessionLayout = JSON.parse(props.videoLayout);

    // check if new streamId already assigned to another pos
    // if yes, this pos needs to be assigned a different stream. take streamId of pos being changed and assign it
    const existingVideoPos = getStreamPosition(selectedStreamId, sessionLayout);
    if (existingVideoPos) {
      sessionLayout[props.projectId][props.layoutName] = {
        ...sessionLayout[props.projectId][props.layoutName],
        [existingVideoPos]:
          sessionLayout[props.projectId][props.layoutName][videoPos]
      };
    }

    //  assign the selected streamId to selected Pos
    sessionLayout[props.projectId][props.layoutName] = {
      ...sessionLayout[props.projectId][props.layoutName],
      [videoPos]: selectedStreamId
    };

    props.setVideoLayout(JSON.stringify(sessionLayout));
  };

  return (
    <div>
      <Card className={classes.childCard}>
        <CardMedia>
          <div className={classes.playerWrapper}>
            <ReactPlayer
              ref={playerRefs.current[streamId]}
              className={classes.reactPlayer}
              width='100%'
              height='100%'
              url={getStreamUrl(streamId.toString(), false)}
              playing={true}
              volume={0}
              muted={true}
              onError={handleOnError}
              playsinline={true}
              config={{
                file: {
                  hlsVersion: '0.13.2',
                  hlsOptions: {
                    liveBackBufferLength: 1,
                    xhrSetup(xhr: any, _url: string) {
                      xhr.withCredentials =
                        process.env.REACT_APP_ENVIRONMENT !== 'local'; // send cookies
                    }
                  }
                }
              }}
            />
          </div>
        </CardMedia>
        <div className={classes.row}>
          {loading ? (
            <Skeleton variant='text' width={240} height={24} />
          ) : (
            <>
              <div className={classes.channelText}>CH {props.streamId}</div>
              <FormControl className={classes.formControl}>
                <Select
                  labelId='stream-name-select-label'
                  id='stream-name-simple-select'
                  value={props.streamId}
                  name={props.pos}
                  onChange={handleStreamSelection}
                >
                  {props.streamIds.map(sid => (
                    <MenuItem key={sid} value={sid}>
                      {streamNames[sid]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          <span className={classes.spacer} />
          <CardActions className={classes.button}>
            {isAdmin && (
              <div>
                <Tooltip title='Rename stream'>
                  <IconButton
                    size='small'
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => {
                      handleEdit(streamId);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Divider orientation='vertical' flexItem={true} />
              </div>
            )}

            <Tooltip title='Open HD stream'>
              <IconButton
                size='small'
                component={RouterLink}
                to={getStreamRoute(streamId)}
              >
                <Hd />
              </IconButton>
            </Tooltip>
            <Tooltip title='Seek to latest'>
              <IconButton
                size='small'
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => {
                  handleSeekLatest(streamId);
                }}
              >
                <LastPage />
              </IconButton>
            </Tooltip>
          </CardActions>
        </div>
      </Card>
      <GenericDialog
        open={openRenameStreamDialog}
        title={`Rename ${streamNames[streamIdToChange]}`}
        message={'Please enter the new name'}
        onClose={handleCloseRenameStreamDialog}
        okButtonText={'Ok'}
        okHandler={handleRenameStream}
        cancelHandler={handleCloseRenameStreamDialog}
        okButtonDisabled={
          streamName.length === 0 || textFieldError.length !== 0
        }
      >
        <TextField
          autoFocus={true}
          error={textFieldError.length !== 0}
          helperText={textFieldError}
          margin='dense'
          id='name'
          label='New name'
          onChange={handleStreamNameChange}
          type='text'
          fullWidth={true}
        />
      </GenericDialog>
    </div>
  );
}
