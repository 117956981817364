import { CONFIG } from '../services';

export interface StreamInfo {
  name: string;
  url: string;
}

function getStreamBaseUrl(id: string): string {
  const baseUrl = CONFIG.streaming.baseUrl;
  return baseUrl + `/channel-${id}`;
}

export function getStreamUrl(id: string, hd: boolean): string {
  const url = getStreamBaseUrl(id);
  if (url) {
    if (hd) {
      return url + '/hda/main.m3u8';
    }
    return url + '/sda/main.m3u8';
  }
  // If url is empty ReactPlayer will append localhost:3000 in front of /sda/main.m3u8
  return '';
}
