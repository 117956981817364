import { Container, createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import {
  IdList,
  LayoutProps,
  sessionStreamAssignment
} from './BaseVideoLayout';
import { VideoLayout } from './ProjectStreams';
import Stream from './Stream';
import useCalculatedWidth from './useCalculatedWidth';

const useStyles = makeStyles(() =>
  createStyles({
    root: {}
  })
);

enum VideoPosition {
  pos11,
  pos12,
  pos13,
  pos14,
  pos21,
  pos22,
  pos23,
  pos24,
  pos31,
  pos32,
  pos33,
  pos34
}

export default function VideoLayout3x4(props: LayoutProps): JSX.Element {
  const classes = useStyles();
  const streamIds = props.streamIds;
  const projectId = props.projectId;
  const videoLayoutCapacity = 12;

  const defaultStreamAssignment = {
    pos11: streamIds.length > 0 ? streamIds[0] : 0,
    pos12: streamIds.length > 1 ? streamIds[1] : 0,
    pos13: streamIds.length > 2 ? streamIds[2] : 0,
    pos14: streamIds.length > 3 ? streamIds[3] : 0,
    pos21: streamIds.length > 4 ? streamIds[4] : 0,
    pos22: streamIds.length > 5 ? streamIds[5] : 0,
    pos23: streamIds.length > 6 ? streamIds[6] : 0,
    pos24: streamIds.length > 7 ? streamIds[7] : 0,
    pos31: streamIds.length > 8 ? streamIds[8] : 0,
    pos32: streamIds.length > 9 ? streamIds[9] : 0,
    pos33: streamIds.length > 10 ? streamIds[10] : 0,
    pos34: streamIds.length > 11 ? streamIds[11] : 0
  };

  const getStreamId = (layoutPosition: VideoPosition): number => {
    const streamAssignment = sessionStreamAssignment(
      props.videoLayout,
      projectId,
      streamIds,
      defaultStreamAssignment,
      props.setVideoLayout,
      VideoLayout.layout3x4,
      videoLayoutCapacity
    );

    switch (layoutPosition) {
      case VideoPosition.pos11:
        return streamAssignment.pos11;
      case VideoPosition.pos12:
        return streamAssignment.pos12;
      case VideoPosition.pos13:
        return streamAssignment.pos13;
      case VideoPosition.pos14:
        return streamAssignment.pos14;
      case VideoPosition.pos21:
        return streamAssignment.pos21;
      case VideoPosition.pos22:
        return streamAssignment.pos22;
      case VideoPosition.pos23:
        return streamAssignment.pos23;
      case VideoPosition.pos24:
        return streamAssignment.pos24;
      case VideoPosition.pos31:
        return streamAssignment.pos31;
      case VideoPosition.pos32:
        return streamAssignment.pos32;
      case VideoPosition.pos33:
        return streamAssignment.pos33;
      case VideoPosition.pos34:
        return streamAssignment.pos34;
    }
  };

  const videoContainer = (pos: VideoPosition) => {
    return getStreamId(pos) ? (
      <Stream
        streamId={getStreamId(pos)}
        pos={VideoPosition[pos]}
        streamIds={streamIds}
        projectId={projectId}
        setVideoLayout={props.setVideoLayout}
        videoLayout={props.videoLayout}
        layoutName={VideoLayout[VideoLayout.layout3x4]}
        streamNames={props.streamNames as IdList}
        propogateStreamNameChange={props.propogateStreamNameChange}
      />
    ) : null;
  };

  const width = useCalculatedWidth(110, 100, 1.75);

  return (
    <Container style={{ maxWidth: width }}>
      <Grid className={classes.root} container={true} spacing={2}>
        <Grid key={1} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos11)}
        </Grid>
        <Grid key={2} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos12)}
        </Grid>
        <Grid key={3} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos13)}
        </Grid>
        <Grid key={4} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos14)}
        </Grid>
        <Grid key={5} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos21)}
        </Grid>
        <Grid key={6} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos22)}
        </Grid>
        <Grid key={7} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos23)}
        </Grid>
        <Grid key={8} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos24)}
        </Grid>
        <Grid key={9} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos31)}
        </Grid>
        <Grid key={10} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos32)}
        </Grid>
        <Grid key={11} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos33)}
        </Grid>
        <Grid key={12} item={true} xs={12} md={3} sm={12}>
          {videoContainer(VideoPosition.pos34)}
        </Grid>
      </Grid>
    </Container>
  );
}
