import { CONFIG } from '../services';

export function allOrganizationsUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations`;
}

export function allUsersUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/users`;
}

export function getOrganizationProjectsUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${id}/projects`;
}

export function getOrganizationUsersUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${id}/users`;
}

export function organizationUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${id}`;
}

export function usersUrl(id: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${id}/users`;
}

export function userUrl(organizationId: string, userId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${organizationId}/users/${userId}`;
}

export function projectUrl(organizationId: string, projectId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/organizations/${organizationId}/projects/${projectId}`;
}

export function usersAssignedUrl(projectId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/users-assigned/${projectId}`;
}

export function assignUserUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/assign-user-to-project`;
}

export function unassignUserUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/unassign-user-from-project`;
}

export function projectsAssignedUrl(userId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/projects-assigned/${userId}`;
}

export function streamIdsUrl(userId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/projects-assigned/${userId}`;
}

export function welcomeEmailUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/welcome-email`;
}

export function authZAdminsUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/authz-admins`;
}

export function adminStreamNamesUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/stream-names`;
}

export function auth0UsersUrl(): string {
  return `${CONFIG.api.baseUrl}/api/admin/auth0/users`;
}

export function auth0UsersWithEmailUrl(email: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/auth0/users?email=${email}`;
}

export function deleteAuth0UserUrl(userEmail: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/auth0/users/${userEmail}`;
}

export function startChannelUrl(channelId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/channel/start/${channelId}`;
}

export function stopChannelUrl(channelId: string): string {
  return `${CONFIG.api.baseUrl}/api/admin/channel/stop/${channelId}`;
}
