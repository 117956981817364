import {
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core';
import Cookies from 'js-cookie';
// tslint:disable-next-line:no-submodule-imports
import React, { RefObject, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from 'react-router-dom';
import { IProjectStreamsDto } from '../../types/dto.type';
import { get } from '../api/axios';
import { apiUrls } from '../api/urls';
import { CONFIG } from '../services';
import { ProjectIdParams } from '../types/props.type';
import BaseVideoLayout from './BaseVideoLayout';
import { useStateWithLocalStorage } from './toolbox';
import VideoLayout1x1 from './VideoLayout1x1';
import VideoLayout1x2 from './VideoLayout1x2';
import VideoLayout2x2 from './VideoLayout2x2';
import VideoLayout2x4 from './VideoLayout2x4';
import VideoLayout3x4 from './VideoLayout3x4';
import VideoLayout4x4 from './VideoLayout4x4';

const cloudFrontPolicy = 'CloudFront-Policy';
const cloudFrontKeyPairId = 'CloudFront-Key-Pair-Id';
const cloudFrontSignature = 'CloudFront-Signature';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center'
    },
    childCard: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1)
    },
    playerWrapper: {
      position: 'relative',
      paddingTop: '56.25%'
    },
    reactPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    },
    button: {
      padding: 0
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    spacer: {
      flexGrow: 1
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: theme.spacing(1)
    },
    formControl: {
      marginBottom: theme.spacing(1),
      minWidth: 120
    },
    layoutSelect: {
      padding: '0.5em 2em',
      textAlign: 'center'
    },
    layoutSelectContainer: {
      textAlign: 'right'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })
);

export enum VideoLayout {
  'layout1x1',
  'layout1x2',
  'layout2x2',
  'layout2x4',
  'layout1L+5',
  'layout3x4',
  'layout4x4'
}

export default function ProjectStreams(
  props: RouteComponentProps<ProjectIdParams>
): JSX.Element {
  const classes = useStyles();

  const [startPlaying, setStartPlaying] = useState(false);
  const [videoLayout, setVideoLayout] = useStateWithLocalStorage('VideoLayout');
  const initialPlayerRefs: Array<RefObject<ReactPlayer>> = [];
  const playerRefs = useRef(initialPlayerRefs);
  const [projectStreams, setProjectStreams] = useState<IProjectStreamsDto>();
  const [streamIds, setStreamIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchSignedCookie = async () => {
      let cookiesSet = 0;
      for (const streamId of streamIds) {
        const cookieDomain = CONFIG.api.baseUrl.split('//')[1];
        const url = CONFIG.api.baseUrl + `/api/signedcookie/${streamId}`;
        const response = await get(url);
        Cookies.set(cloudFrontPolicy, response[cloudFrontPolicy], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain,
          secure: true
        });
        Cookies.set(cloudFrontKeyPairId, response[cloudFrontKeyPairId], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain,
          secure: true
        });
        Cookies.set(cloudFrontSignature, response[cloudFrontSignature], {
          path: `/channel-${streamId}/`,
          domain: cookieDomain,
          secure: true
        });
        cookiesSet++;
      }
      if (cookiesSet > 0 && cookiesSet === streamIds.length) {
        setStartPlaying(true);
      }
    };

    const fetchProjectStreams = async () => {
      let value = props.location.state as IProjectStreamsDto;
      if (!value) {
        const url = apiUrls.projectStreamsV2(props.match.params.projectId);
        value = (await get(url)) as IProjectStreamsDto;
      }

      if (value.streams.length !== streamIds.length) {
        setStreamIds(value.streams);
      }

      for (const streamId of value.streams) {
        playerRefs.current[streamId] = React.createRef<ReactPlayer>();
      }

      if (
        process.env.REACT_APP_ENVIRONMENT === undefined ||
        process.env.REACT_APP_ENVIRONMENT.toLocaleLowerCase() !== 'local'
      ) {
        await fetchSignedCookie();
      } else {
        setStartPlaying(true);
      }

      const defaultLayout = {
        [value.projectId]: {
          activeLayout: VideoLayout[VideoLayout.layout2x2],
          projectName: value.projectName
        }
      };
      if (!videoLayout) {
        setVideoLayout(JSON.stringify(defaultLayout));
      }

      setProjectStreams(value);
    };
    fetchProjectStreams();
  }, [
    props.location.state,
    props.match.params.projectId,
    streamIds,
    videoLayout,
    setVideoLayout
  ]);

  if (!projectStreams) {
    return <CircularProgress size={40} className={classes.progress} />;
  }

  const getVideoLayout = () => {
    if (!startPlaying) {
      return <CircularProgress size={40} className={classes.progress} />;
    }
    // set default layout
    let layout: VideoLayout = VideoLayout.layout2x2;

    if (videoLayout) {
      const sessionLayout = JSON.parse(videoLayout);
      if (sessionLayout?.[projectStreams.projectId]?.activeLayout) {
        layout =
          VideoLayout[
            sessionLayout[projectStreams.projectId]
              .activeLayout as keyof typeof VideoLayout
          ];
      }
    }
    switch (layout) {
      case VideoLayout.layout1x1:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout1x1
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
      case VideoLayout.layout1x2:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout1x2
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
      case VideoLayout.layout2x2:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout2x2
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
      case VideoLayout.layout2x4:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout2x4
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
      case VideoLayout.layout3x4:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout3x4
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
      case VideoLayout.layout4x4:
        return (
          <BaseVideoLayout streamIds={streamIds}>
            <VideoLayout4x4
              streamIds={streamIds}
              projectId={projectStreams.projectId}
              setVideoLayout={setVideoLayout}
              videoLayout={videoLayout}
            />
          </BaseVideoLayout>
        );
    }
  };

  const getTabIndex = () => {
    if (videoLayout) {
      const sessionLayout = JSON.parse(videoLayout);
      if (sessionLayout?.[projectStreams.projectId]?.activeLayout) {
        return VideoLayout[
          sessionLayout[projectStreams.projectId]
            .activeLayout as keyof typeof VideoLayout
        ];
      }
    }

    return VideoLayout.layout2x2;
  };

  const handleLayoutChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const layout = event.target.value as VideoLayout;
    let sessionLayout = {
      [projectStreams.projectId]: {
        activeLayout: VideoLayout[layout],
        projectName: projectStreams.projectName
      }
    };

    if (videoLayout) {
      sessionLayout = JSON.parse(videoLayout);

      if (sessionLayout[projectStreams.projectId]) {
        sessionLayout[projectStreams.projectId].activeLayout =
          VideoLayout[layout];
      }
    }

    setVideoLayout(JSON.stringify(sessionLayout));
  };

  return (
    <div>
      <Grid className={classes.root} container={true} spacing={1}>
        <Grid
          key={2}
          item={true}
          xs={12}
          classes={{ root: classes.layoutSelectContainer }}
        >
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='demo-simple-select-outlined-label'>
              Layout
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={getTabIndex()}
              onChange={handleLayoutChange}
              label='Layout'
              classes={{ root: classes.layoutSelect }}
            >
              <MenuItem value={VideoLayout.layout1x1}>1x1</MenuItem>
              <MenuItem value={VideoLayout.layout1x2}>1x2</MenuItem>
              <MenuItem value={VideoLayout.layout2x2}>2x2</MenuItem>
              <MenuItem value={VideoLayout.layout2x4}>2x4</MenuItem>
              <MenuItem value={VideoLayout.layout3x4}>3x4</MenuItem>
              <MenuItem value={VideoLayout.layout4x4}>4x4</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {getVideoLayout()}
    </div>
  );
}
